import { Injectable } from '@angular/core';
import { ICountry } from '../interfaces/country.interface';
import { HttpService } from '../api/http.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  countries: ICountry[] = [];
  constructor(private httpService: HttpService) {
    this.getCountries();
  }

  private getCountries(): void {
    this.httpService.getData('./assets/data/countries.json').subscribe({
      next: (res) => {
        this.countries = res;
      }, error: err => {
        console.log('Country json loading failed.');
      }
    });
  }

  getCountryNameByCode(code: string): string {
    const country = this.countries.find((country: any) => country.code === code);
    return country ? country.name : '';
  }

  findCountryByNumber(phone: string): any {
    return this.countries.find(item => phone.includes(item.dial_code));
  }
}
