import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/authentication/authentication.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpReqInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headerObj;

    if (request.url.includes("/login") || request.url.includes("/forgot-password") || request.url.includes("/reset-password") || request.url.includes("/assets/data")) {

      headerObj = { "Content-Type": "application/json", Accept: "application/json" }

    } else if (request.url.includes("/logo") || request.url.includes("/image")) {

      const headerToken = this.authService.currentUserValue.jwtToken;
      headerObj = { Authorization: "Bearer " + headerToken }

    } else {

      const headerToken = this.authService.currentUserValue.jwtToken;
      headerObj = { Authorization: "Bearer " + headerToken, "Content-Type": "application/json", Accept: "application/json" }

    }

    let headers = new HttpHeaders(headerObj);

    request = request.clone({
      headers: headers,
      setParams: {
        locale: "en",
      },
    });

    return next.handle(request);
  }

}
