import { Injectable } from '@angular/core';
import { ApiEndpoints } from '../api/api-endpoints';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { map } from 'rxjs/operators';
import { UserModel } from '@app/shared/models/user.model';
import { Router } from '@angular/router';

interface UserRequest {
  username: string;
  password: string;
}

const localStorageEntities = {
  UserCreateModel: 'userCreateModel',
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;
  constructor(private http: HttpClient,
    private router: Router) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(String(localStorage.getItem(localStorageEntities.UserCreateModel))));;
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  loginUser(payload: UserRequest): Observable<any> {
    return this.http.post(ApiEndpoints.login, payload).pipe(map((user: any) => {
      localStorage.setItem(localStorageEntities.UserCreateModel, JSON.stringify(user));
      this.currentUserSubject.next(user);
      return user;
    }));
  }

  loggedUser(): Observable<any> {
    return this.http.get(ApiEndpoints.getLoggedInUser).pipe(map((user: any) => {
      user.jwtToken = this.currentUserValue.jwtToken;
      localStorage.setItem(localStorageEntities.UserCreateModel, JSON.stringify(user));
      this.currentUserSubject.next(user);
      return user;
    }));
  }

  logoutUser(): Observable<any> {
    return this.http.post(ApiEndpoints.loginOut, {}).pipe(map((res: any) => {
      return res;
    }));
  }

  clearUser(): void {
    localStorage.removeItem(localStorageEntities.UserCreateModel);
    this.currentUserSubject.next(null!);
    this.router.navigate(['/']);
  }
}
