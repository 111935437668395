import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthenticationService,
        private router: Router) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const currentUser = this.authService.currentUserValue;
        if (!currentUser) {
            return true;
        } else {
            switch(currentUser.userRoleName) {
                case 'SUPER_ADMIN': {
                    this.router.navigate(['/s/a/company']);
                    break;
                }
                case 'COMPANY_ADMIN': {
                    this.router.navigate(['/c/a/company']);
                    break;
                }
                default: {
                    this.router.navigate(['/retailer/customer']);
                    break;
                }
            }
            return false;
        }

    }

}
