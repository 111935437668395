import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@core/errors/page-not-found/page-not-found.component';
import { AdminAuthGuard } from './core/guards/admin-auth.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { RetailerAuthGuard } from './core/guards/retailer-auth.guard';
import { CompanyAdminAuthGuard } from './core/guards/company-admin-auth.guard';

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], loadChildren: () => import('./modules/auth/auth.module').then( m => m.AuthModule) },
  { path: 's/a', canActivate: [AdminAuthGuard], loadChildren: () => import('./modules/super-admin/super-admin.module').then( m => m.SuperAdminModule) },
  { path: 'c/a', canActivate: [CompanyAdminAuthGuard], loadChildren: () => import('./modules/company-admin/company-admin.module').then( m => m.CompanyAdminModule) },
  { path: 'retailer', canActivate: [RetailerAuthGuard], loadChildren: () => import('./modules/retailer/retailer.module').then( m => m.RetailerModule) },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
