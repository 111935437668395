import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  getData(url: string): Observable<any> {
    return this.http.get(url);
  }

  postData(url: string, data?: any): Observable<any> {
    return this.http.post(url, data);
  }

  putData(url: string, data: any): Observable<any> {
    return this.http.put(url, data);
  }

  patchData(url: string, data: any): Observable<any> {
    return this.http.patch(url, data);
  }

  deleteData(url: string): Observable<any> {
    return this.http.delete(url);
  }

}
