import { Component } from '@angular/core';
import { CountryService } from './core/services/country.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kioraa-rewards-admin';
  //CountryService is injected into this component to initiate the service upon the application's first load
  constructor(private countryService: CountryService) {document.documentElement.style.overflow = 'hidden';}
}
