import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '@core/authentication/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
        // auto logout if 401 response returned from api
        this.authService.clearUser();
      }
      let error;
      console.log(err);
      if(err.error.messages) {
        error = {
          code: err.error.code,
          status: err.status,
          message: err.error.messages[0]
        };
      } else {
        error = {
          message: err.error.text
        };
      }
      
      console.log(error);
      return throwError(error);
    }))
  }
}
